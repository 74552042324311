import { AxiosSuccess, AxiosError } from '@/helpers/axiosHelper'
import { Filter, VuexFilterListNamespaces } from '@/typings/TokenFilter'
import { bootstrap, AxiosResponseAPI } from '@/helpers/axiosHelper'

export const { http, emits } = bootstrap('user-filter', {})

export async function createUserFilter(data: {
  user: string
  title: string
  namespace: VuexFilterListNamespaces
  tokens: Filter[]
}): AxiosResponseAPI<any> {
  try {
    const response = await http.request<any>({
      url: ``,
      method: 'post',
      data,
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function setUserBookmark(
  userId: string,
  data: {
    title: string
    namespace: VuexFilterListNamespaces
  }
): AxiosResponseAPI<any> {
  try {
    const response = await http.request<any>({
      url: `/bookmark/${userId}`,
      method: 'put',
      data,
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function deleteUserFilter(filterId: string): AxiosResponseAPI<any> {
  try {
    const response = await http.request<any>({
      url: `/${filterId}`,
      method: 'delete',
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}
