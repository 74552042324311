import Vue from 'vue'
import Vuex from 'vuex'

import settings from '@/store/settings'
import auth from '@/store/auth'
import orderStatus from '@/store/orderStatus'

import filters from '@/store/filters'
import createPersistedState from 'vuex-persistedstate'

import { getModule, VuexModule } from 'vuex-module-decorators'
import { isDevelopement } from '@/config'

export type ConstructorOf<C> = {
  new (...args: any[]): C
}

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {},
  modules: {
    settings,
    auth,
    filters,
    orderStatus,
  },
  strict: isDevelopement,
  plugins: [
    createPersistedState({
      paths: ['settings', 'auth'],
    }),
  ],
})

function getModuleProxy<M extends VuexModule>(S: ConstructorOf<M>): M {
  return getModule(S, store)
}

export const settingsModule = getModuleProxy(settings)
export const orderStatusModule = getModuleProxy(orderStatus)
export const authModule = getModuleProxy(auth)
export const filtersModule = getModuleProxy(filters)

export default store
