export const settings = import.meta.env.VITE_SETTINGS

export const isProduction = settings === 'production'
export const isStaging = settings === 'staging'
export const isDevelopement = settings === 'developement'

export const apiUrl = import.meta.env.VITE_API_URL
export const apiSocket = import.meta.env.VITE_SOCKET_URL
export const apiVersion = import.meta.env.VITE_API_VERSION

export const vcsRef = import.meta.env.VITE_VCS_REF
