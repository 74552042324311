import { generatedFilters } from '@/helpers/filterHelper'
import { Filter, VuexFilterListItemEntry, VuexFilterListNamespaces } from '@/typings/TokenFilter'
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { cloneDeep } from 'lodash'
import { authModule } from '@/store'
import startOfDay from 'date-fns/startOfDay'
import formatISO from 'date-fns/formatISO'
import * as userFilterAPI from '@/api/userFilter'

@Module({
  namespaced: true,
  name: 'filters',
})
export default class Filters extends VuexModule {
  public filterList = generatedFilters.filterList

  get defaultFilters() {
    return (filterType: VuexFilterListNamespaces) => {
      return this.filterList[filterType].default || []
    }
  }

  get customFilters() {
    return (filterType: VuexFilterListNamespaces) =>
      authModule.user?.filters?.customFilters
        ?.filter((filter) => filter.namespace === filterType)
        .map((e) => ({
          _id: e._id,
          user: e.user,
          name: e.title,
          filter: e.tokens,
        })) || []
  }

  get currentFilter() {
    return (namespace: VuexFilterListNamespaces) => {
      return this.filterList[namespace]?.current
    }
  }

  get userDefaultFilter() {
    return (namespace: VuexFilterListNamespaces) => {
      const userDefaultFilters = authModule.user?.filters?.defaultFilters
      const defaultFilters = this.defaultFilters(namespace)
      const customFilters = this.customFilters(namespace)

      // first checking if we have persisted default filter
      if (userDefaultFilters && userDefaultFilters[namespace]) {
        const filter = [...defaultFilters, ...customFilters].find(
          (filter) => filter.name === userDefaultFilters[namespace]
        )

        if (filter) {
          return filter
        }
      }

      // second we try to find first aviable default filter
      if (defaultFilters.length) {
        const defaultFilter = defaultFilters[0]

        return defaultFilter
      }

      // third we try to find first aviable custom filter
      if (customFilters.length) {
        const сustomFilter = customFilters[0]

        return сustomFilter
      }

      // finally return empty if we found nothing
      return ''
    }
  }

  @Mutation
  SET_DEFAULT_FILTER(filter: { name: VuexFilterListNamespaces; data: VuexFilterListItemEntry[] }) {
    this.filterList[filter.name].default = filter.data
  }

  @Mutation
  SET_CURRENT_FILTER(filter: { name: VuexFilterListNamespaces; data: Filter[] }) {
    this.filterList[filter.name].current = filter.data
  }

  @Mutation
  CLEAR_FILTERS() {
    this.filterList = generatedFilters.filterList
  }

  @Action
  async clearFilters() {
    this.context.commit('CLEAR_FILTERS')
  }

  @Action
  async addDefault(filter: { name: VuexFilterListNamespaces; data: VuexFilterListItemEntry }) {
    const defaultFilter = this.filterList[filter.name].default || []

    const existingFilterIndex = defaultFilter.findIndex((e) => e.name === filter.data.name)

    if (existingFilterIndex !== -1) {
      const copyDefaultFilter = cloneDeep(defaultFilter)

      copyDefaultFilter[existingFilterIndex] = filter.data

      this.context.commit('SET_DEFAULT_FILTER', {
        name: filter.name,
        data: copyDefaultFilter,
      })

      return
    }

    this.context.commit('SET_DEFAULT_FILTER', {
      name: filter.name,
      data: [...defaultFilter, filter.data],
    })
  }

  @Action
  addCurrent(filter: { name: VuexFilterListNamespaces; data: VuexFilterListItemEntry }) {
    this.context.commit('SET_CURRENT_FILTER', {
      name: filter.name,
      data: filter.data,
    })
  }

  @Action
  removeCurrent(filter: { name: VuexFilterListNamespaces }) {
    this.context.commit('SET_CURRENT_FILTER', {
      name: filter.name,
      data: [],
    })
  }

  @Action
  setCustomFilter(payload: { namespace: VuexFilterListNamespaces; filterName: string }) {
    const filter = this.customFilters(payload.namespace).find(
      (customFilter) => customFilter.name === payload.filterName
    ) || { filter: [] }

    this.context.commit('SET_CURRENT_FILTER', {
      name: payload.namespace,
      data: cloneDeep(filter.filter),
    })
  }

  @Action
  async deleteCustomFilter(payload: { namespace: VuexFilterListNamespaces; filterName: string }) {
    const filter = this.customFilters(payload.namespace).find(
      (customFilter) => customFilter.name === payload.filterName
    )

    if (filter) {
      const response = await userFilterAPI.deleteUserFilter(filter._id)

      if (response.status !== 200) {
        return { type: 'ERROR', message: response.message }
      }

      return { type: 'SUCCESS', message: 'Фильтр успешно удалён' }
    }
  }

  @Action
  async setBookmark(payload: { namespace: VuexFilterListNamespaces; filterName: string }) {
    const response = await userFilterAPI.setUserBookmark(authModule.user?._id as string, {
      namespace: payload.namespace,
      title: payload.filterName,
    })

    if (response.status !== 200) {
      return { type: 'ERROR', message: response.message }
    }

    return { type: 'SUCCESS', message: 'Фильтр успешно удалён' }
  }

  @Action
  setDefaultFilter(payload: { name: VuexFilterListNamespaces; filterName: string }) {
    this.context.commit('SET_CURRENT_FILTER', {
      name: payload.name,
      data: cloneDeep(this.filterList[payload.name].default.find((e) => e.name === payload.filterName)?.filter),
    })
  }

  @Action
  async trySetDefaultFilter(payload: { namespace: VuexFilterListNamespaces }) {
    const userDefaultFilter = this.userDefaultFilter(payload.namespace)

    if (typeof userDefaultFilter === 'string') {
      return false
    }

    await this.setBookmark({ namespace: payload.namespace, filterName: userDefaultFilter?.name as string })

    this.context.commit('SET_CURRENT_FILTER', {
      name: payload.namespace,
      data: userDefaultFilter?.filter,
    })

    return true
  }

  @Action
  async initDefaultFilter(type: VuexFilterListNamespaces) {
    const filtersList: any = []
    if (type === 'orders') {
      if (authModule.user) {
        filtersList.push({
          name: 'Заказы где я мастер',
          filter: [
            {
              token: {
                value: 'master',
                type: 'array',
                name: 'Мастер',
                autocomplete: '/custom?m=Order&f=master.credentials&v=master._id&w=y',
                compares: ['contains', 'not contains'],
                disabled: false,
              },

              value: [
                {
                  text: authModule.user?.credentials as string,
                  value: authModule.user?._id as string,
                },
              ],

              compares: 'contains',
              disabled: false,
              display: false,
            },
          ],
        })

        filtersList.push({
          name: 'Заказы где я менеджер',
          filter: [
            {
              token: {
                value: 'manager',
                type: 'array',
                name: 'Менеджер',
                autocomplete: '/custom?m=Order&f=manager.credentials&v=manager._id&w=y',
                compares: ['contains', 'not contains'],
                disabled: false,
              },

              value: [
                {
                  text: authModule.user?.credentials as string,
                  value: authModule.user?._id as string,
                },
              ],

              compares: 'contains',
              disabled: false,
              display: false,
            },
          ],
        })
      }

      filtersList.push({
        name: 'Просроченые заказы',
        filter: [
          {
            token: {
              value: 'estimatedCloseAt',
              type: 'date',
              name: 'Примерная дата готовности',
              compares: ['between', 'greater than', 'not greater than'],
              disabled: false,
            },

            value: formatISO(startOfDay(new Date())),

            compares: 'not greater than',
            disabled: false,
            display: false,
          },
          {
            token: {
              value: 'status',
              type: 'array',
              name: 'Статус',
              autocomplete: '/custom?m=Order&f=status?w=y',
              compares: ['contains', 'not contains'],
              disabled: false,
            },

            value: [
              {
                text: 'Закрыт',
                value: 'Закрыт',
              },
            ],

            compares: 'not contains',
            disabled: false,
            display: false,
          },
        ],
      })

      filtersList.push({
        name: 'Исключить закрытые заказы',
        filter: [
          {
            token: {
              value: 'status',
              type: 'array',
              name: 'Статус',
              autocomplete: '/custom?m=Order&f=status&w=y',
              compares: ['contains', 'not contains'],
              disabled: false,
            },

            value: [
              {
                text: 'Закрыт',
                value: 'Закрыт',
              },
            ],

            compares: 'not contains',
            disabled: false,
            display: false,
          },
        ],
      })

      filtersList.push({
        name: 'Только закрытые заказы',
        filter: [
          {
            token: {
              value: 'status',
              type: 'array',
              name: 'Статус',
              autocomplete: '/custom?m=Order&f=status&w=y',
              compares: ['contains', 'not contains'],
              disabled: false,
            },

            value: [
              {
                text: 'Закрыт',
                value: 'Закрыт',
              },
            ],

            compares: 'contains',
            disabled: false,
            display: false,
          },
        ],
      })
    }

    for (const filterKey in filtersList) {
      await this.context.dispatch('addDefault', {
        name: type,
        data: filtersList[filterKey],
      })
    }

    await this.context.dispatch('trySetDefaultFilter', {
      namespace: type,
    })
  }

  @Action
  async initDefaultFilters() {
    const filterTypes = Object.keys(this.filterList) as VuexFilterListNamespaces[]

    for (const filterType of filterTypes) {
      await this.initDefaultFilter(filterType)
    }
  }
}
