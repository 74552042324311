import { AxiosSuccess, AxiosError } from '@/helpers/axiosHelper'
import { Office } from '@/typings/api/office'

import { bootstrap, AxiosResponseAPI } from '@/helpers/axiosHelper'

export const { http, emits } = bootstrap('office', {
  updatedAll: 'offices updated',
  updated: 'office updated',
  deleted: 'office deleted',
  created: 'office created',
})

export async function getAll(): AxiosResponseAPI<Office[]> {
  try {
    const response = await http.request<Office[]>({
      url: ``,
      method: 'get',
    })

    return response as AxiosSuccess<Office[]>
  } catch (error) {
    return error as AxiosError
  }
}

export async function getNextOfficeCode(): AxiosResponseAPI<Office[]> {
  try {
    const response = await http.request<Office[]>({
      url: `/next-office-code`,
      method: 'get',
    })

    return response as AxiosSuccess<Office[]>
  } catch (error) {
    return error as AxiosError
  }
}

export async function create(data: Partial<Office>): AxiosResponseAPI<Office> {
  try {
    const response = await http.request<Office>({
      url: ``,
      method: 'post',
      data,
    })

    return response as AxiosSuccess<Office>
  } catch (error) {
    return error as AxiosError
  }
}

export async function updateOffice(id: string, data: Partial<Office>): AxiosResponseAPI<Office> {
  try {
    const response = await http.request<Office>({
      url: `/${id}`,
      method: 'put',
      data,
    })

    return response as AxiosSuccess<Office>
  } catch (error) {
    return error as AxiosError
  }
}

export async function deleteOffice(id: string, data: Partial<Office>): AxiosResponseAPI<Office> {
  try {
    const response = await http.request<Office>({
      url: `/${id}`,
      method: 'delete',
      data,
    })

    return response as AxiosSuccess<Office>
  } catch (error) {
    return error as AxiosError
  }
}
