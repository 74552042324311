import { bootstrap, AxiosResponseAPI, AxiosSuccess, AxiosError } from '@/helpers/axiosHelper'

export const { http, emits } = bootstrap('order-status', {
  updatedAll: 'order statuses updated',
})

type GroupedStatuses = {
  _id: string
  title: string
  color: string
  statuses: {
    _id: string
    title: string
  }[]
}[]

type OrderStatusInput =
  | {
      action: 'create'
      group: {
        title: string
        color: string
      }

      status: {
        title: string
      }
    }
  | {
      action: 'add'
      group: string
      status: {
        title: string
      }
    }

type OrderStatusGroup = { title: string; color: string; workspace: string }

export async function getGroupedStatuses(): AxiosResponseAPI<any> {
  try {
    const response = await http.request<GroupedStatuses>({
      url: ``,
      method: 'get',
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function getAllGroups(): AxiosResponseAPI<any> {
  try {
    const response = await http.request({
      url: `/groups`,
      method: 'get',
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function getAllStatuses(): AxiosResponseAPI<any> {
  try {
    const response = await http.request({
      url: `/statuses`,
      method: 'get',
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function getOrdersByStatus(status: string): AxiosResponseAPI<any> {
  try {
    const response = await http.request({
      url: `/orders/${status}`,
      method: 'get',
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function createGroup(data: Exclude<OrderStatusGroup, 'workspace'>): AxiosResponseAPI<any> {
  try {
    const response = await http.request({
      url: `/group`,
      method: 'post',
      data,
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function createStatus(data: OrderStatusInput): AxiosResponseAPI<any> {
  try {
    const response = await http.request({
      url: `/status`,
      method: 'post',
      data,
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function updateStatus(id: string, title: string): AxiosResponseAPI<any> {
  try {
    const response = await http.request({
      url: `/status/${id}`,
      method: 'put',
      data: {
        title,
      },
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function updateGroup(id: string, query: any): AxiosResponseAPI<any> {
  try {
    const response = await http.request({
      url: `/group/${id}`,
      method: 'put',
      data: query,
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function deleteGroup(id: string): AxiosResponseAPI<any> {
  try {
    const response = await http.request({
      url: `/group/${id}`,
      method: 'delete',
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function deleteStatus(id: string, payload: any): AxiosResponseAPI<any> {
  try {
    const response = await http.request({
      url: `/status/${id}`,
      method: 'delete',
      data: payload,
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}
