import Vue from 'vue'
import VueSocketIOExt from 'vue-socket.io-extended'
import io from 'socket.io-client'
import store from '@/store'
import { apiSocket } from '@/config'

const socket = io(apiSocket as string)

Vue.use(VueSocketIOExt, socket, {
  store,
  reconnect: true,
  reconnectionDelay: 500,
  maxReconnectionAttempts: Infinity,
})
