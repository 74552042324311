
<div class="w:min-h-12 w:px-6 w:relative w:z-10 w:elevation-8 w:flex w:items-center w:bg-black">
  <button
    :class="{ 'is-active': isOpened }"
    class="w:block w:mt-1 hamburger hamburger--collapse"
    type="button"
    aria-label="Меню"
    aria-controls="navigation"
    @click="handleToggle"
  >
    <span class="hamburger-box">
      <span class="hamburger-inner w:text-gray-lightest"></span>
    </span>
  </button>
  <base-heading variant="h2" class="w:flex-grow w:text-center !w:text-gray-lightest">{{ title }}</base-heading>

  <aside
    class="
      app-menu
      w:absolute
      w:h-[calc(100vh_-_42px)]
      w:space-y-4
      w:top-12
      w:bg-black
      w:w-full
      w:left-0
      w:flex
      w:select-none
      w:flex-col
      w:z-9
      w:isolate
    "
    :style="{ transform: 'translateX(-100%)' }"
  >
    <app-menu-header />
    <slot name="top" />
    <app-menu-links class="w:flex-grow" @click="handleClose" />

    <slot name="bottom" />
  </aside>
</div>
