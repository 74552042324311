import { find } from 'lodash'

type Route = {
  name: string
  path: string
  meta: {
    isGuestAllowed?: boolean
    notInAutocomplete?: boolean
    withoutMenu?: boolean
    header: string
  }
}

export type RoutesKey = keyof typeof routes

export type RoutesName<T extends RoutesKey> = typeof routes[T][number]['name']

function getTypedRoutes<R extends readonly Route[] & { name: V }[], V extends string>(...args: R) {
  return args
}

export const routes = {
  reports: getTypedRoutes(
    {
      path: '/reports/daily',
      name: 'reportsOrdersDaily',
      meta: {
        header: 'Ежедневный отчёт',
      },
    },
    {
      path: '/reports/orders-closed-incorrect',
      name: 'reportsOrdersWithoutWork',
      meta: {
        header: 'Отчёт по закрытым на 0',
      },
    },
    {
      path: '/reports/orders-closed',
      name: 'reportsOrdersClosed',
      meta: {
        header: 'Отчёт по закрытым',
      },
    },
    {
      path: '/reports/orders-count',
      name: 'reportsOrdersCount',
      meta: {
        header: 'Отчёт по созданым',
      },
    },
    {
      path: '/reports/orders-profit',
      name: 'reportsOrdersProfit',
      meta: {
        header: 'Отчёт по прибыли',
      },
    }
  ),
  settings: getTypedRoutes(
    {
      path: '/settings/payments',
      name: 'settingsPayments',
      meta: {
        header: 'Оплата',
      },
    },
    {
      path: '/settings/users',
      name: 'settingsUsers',
      meta: {
        header: 'Пользователи',
      },
    },
    {
      path: '/settings/subscriptions',
      name: 'settingsSubscriptions',
      meta: {
        header: 'Подписки',
      },
    },
    {
      path: '/settings/offices',
      name: 'settingsOffices',
      meta: {
        header: 'Филиалы',
      },
    },
    {
      path: '/settings/roles',
      name: 'settingsRoles',
      meta: {
        header: 'Роли',
      },
    },
    {
      path: '/settings/statuses',
      name: 'settingsStatuses',
      meta: {
        header: 'Статусы',
      },
    },
    {
      path: '/settings/posts',
      name: 'settingsPosts',
      meta: {
        header: 'Должности',
      },
    },
    {
      path: '/settings/receipt',
      name: 'settingsReceipt',
      meta: {
        header: 'Квитанции',
      },
    },
    {
      path: '/settings/receipt/:receiptId',
      name: 'settingsReceiptEdit',
      meta: {
        withoutMenu: true,
        header: 'Редактирование квитанции',
      },
    }
  ),
  console: getTypedRoutes({
    path: '/console',
    name: 'console',
    meta: {
      header: 'Консоль администратора',
    },
  }),
  default: getTypedRoutes(
    {
      path: '/',
      name: 'index',
      meta: {
        notInAutocomplete: true,
        header: 'Обзор',
      },
    },
    {
      path: '/register',
      name: 'register',
      meta: {
        isGuestAllowed: true,
        notInAutocomplete: true,
        withoutMenu: true,
        header: 'Регистрация',
      },
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        notInAutocomplete: true,
        isGuestAllowed: true,
        withoutMenu: true,
        header: 'Авторизация',
      },
    },
    {
      path: '/access',
      name: 'access',
      meta: {
        header: 'Журнал событий',
      },
    },
    {
      path: '/access-next',
      name: 'accessNext',
      meta: {
        header: 'Журнал событий',
      },
    },
    {
      path: '/calls',
      name: 'calls',
      meta: {
        header: 'Звонки',
      },
    },
    {
      path: '/calls-next',
      name: 'callsNext',
      meta: {
        header: 'Звонки',
      },
    },
    {
      path: '/cash',
      name: 'cash',
      meta: {
        header: 'Касса',
      },
    },
    {
      path: '/cash-next',
      name: 'cashNext',
      meta: {
        header: 'Касса',
      },
    },
    {
      path: '/clients',
      name: 'clients',
      meta: {
        header: 'Клиенты',
      },
    },
    {
      path: '/clients',
      name: 'clientsNext',
      meta: {
        header: 'Клиенты',
      },
    },
    {
      path: '/orders',
      name: 'orders',
      meta: {
        header: 'Заказы',
      },
    },
    {
      path: '/orders-next',
      name: 'ordersNext',
      meta: {
        header: 'Заказы',
      },
    }
  ),
} as const

export function getRouteByName<K extends RoutesKey>(key: K, name: RoutesName<K>): Route {
  return find(routes[key], (e) => (e as Route).name === name) as Route
}

export function getRoutesAutocomplete() {
  const routesKeys = Object.keys(routes) as ['default', 'reports', 'settings']

  const flattenRoutes = routesKeys.reduce((a, c) => a.concat(routes[c]), [] as Route[])

  const filteredRoutes = flattenRoutes.filter((route) => !route.meta.notInAutocomplete)

  return filteredRoutes.map((route) => ({
    text: route.meta.header,
    value: route.name,
  }))
}

interface RouteMenuSingle {
  single: true
  key: string
  title: string
  name?: RoutesName<RoutesKey>
}

interface RouteMenuMultiple {
  multiple: true
  key: string
  title: string
  active?: boolean
  highlighted?: boolean
  list?: { name: RoutesName<RoutesKey>; title: string }[]
}

type RoutesMenuItem = RouteMenuSingle | RouteMenuMultiple

type RoutesMenu = RoutesMenuItem[]

export const routesMenu: RoutesMenu = [
  {
    key: 'dashboard',
    single: true,
    name: 'index',
    title: 'Обзор',
  },
  {
    key: 'data',
    multiple: true,
    active: false,
    highlighted: false,
    title: 'Данные',
    list: [
      { name: 'ordersNext', title: 'Заказы' },
      { name: 'clientsNext', title: 'Клиенты' },
      { name: 'cashNext', title: 'Касса' },
      { name: 'callsNext', title: 'Звонки' },
      { name: 'accessNext', title: 'Журнал событий' },
      // { name: 'orders', title: 'Заказы' },
      // { name: 'clients', title: 'Клиенты' },
      // { name: 'cash', title: 'Касса' },
      // { name: 'calls', title: 'Звонки' },
      // { name: 'access', title: 'Журнал событий' },
    ],
  },
  {
    key: 'reports',
    multiple: true,
    active: false,
    highlighted: false,
    title: 'Отчёты',
    list: [
      { name: 'reportsOrdersDaily', title: 'Ежедневный' },
      { name: 'reportsOrdersClosed', title: 'Закрыто' },
      { name: 'reportsOrdersWithoutWork', title: 'Закрыто на 0' },
      { name: 'reportsOrdersCount', title: 'Создано' },
      { name: 'reportsOrdersProfit', title: 'Прибыль' },
    ],
  },
  {
    key: 'settings',
    multiple: true,
    active: false,
    highlighted: false,
    title: 'Настройки',
    list: [
      // { name: 'settingsPayments', title: 'Оплата' },
      { name: 'settingsUsers', title: 'Пользователи' },
      { name: 'settingsOffices', title: 'Филиалы' },
      { name: 'settingsRoles', title: 'Роли' },
      { name: 'settingsStatuses', title: 'Статусы' },
      { name: 'settingsPosts', title: 'Должности' },
      { name: 'settingsReceipt', title: 'Квитанции' },
      { name: 'settingsSubscriptions', title: 'Подписки' },
    ],
  },
]
