import { RoleAbility, Role, Roles, RoleInput, AbilityInput } from '@/typings/api/role'
import { bootstrap, AxiosResponseAPI, AxiosSuccess, AxiosError } from '@/helpers/axiosHelper'

export const { http, emits } = bootstrap('role', {
  updatedAll: 'roles updated',
  updated: 'role updated',
  deleted: 'role deleted',
  created: 'role created',
})

export async function getAll(): AxiosResponseAPI<any> {
  try {
    const response = await http.request<Roles>({
      url: ``,
      method: 'get',
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function getOneById(id: string): AxiosResponseAPI<any> {
  try {
    const response = await http.request<Role>({
      url: `${id}`,
      method: 'get',
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function create(data: RoleInput): AxiosResponseAPI<any> {
  try {
    const response = await http.request<Role>({
      url: ``,
      method: 'post',
      data,
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function updateById(name: string, data: Partial<Role>): AxiosResponseAPI<any> {
  try {
    const response = await http.request<Role>({
      url: `${name}`,
      method: 'put',
      data,
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function deleteById(name: string): AxiosResponseAPI<any> {
  try {
    const response = await http.request<Role>({
      url: `${name}`,
      method: 'delete',
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}
