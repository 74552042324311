import {
  VuexFilterList,
  VuexFilterListNamespaces,
  Token,
  TokenCompares,
  TokenPlainTypes,
  TokenValues,
} from '@/typings/TokenFilter'
import { format } from '@/services/dateService'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'

const generateFilters = (filters: VuexFilterListNamespaces[]) => {
  const filterList = Object.fromEntries(
    filters.map((e) => [
      e,
      {
        default: [],
        custom: [],
        current: [],
      },
    ])
  ) as unknown as VuexFilterList

  const returned: {
    filterList: VuexFilterList
  } = {
    filterList,
  }

  return returned
}

const filterList: VuexFilterListNamespaces[] = ['calls', 'cashes', 'clients', 'orders', 'users', 'access']

export const generatedFilters = generateFilters(filterList)

export const comparesTranslate: Record<TokenCompares, string> = {
  is: 'равно %s',
  'not is': 'не равно %s',
  contains: 'содержит %s',
  'not contains': 'не содержит %s',
  between: 'между %s и %s',
  'greater than': 'больше чем %s',
  'not greater than': 'не больше чем %s',
}

export const COMPARES = {
  is: 'равно',
  'not is': 'не равно',
  contains: 'содержит',
  'not contains': 'не содержит',
  between: 'между',
  'greater than': 'больше чем',
  'not greater than': 'не больше чем',
}

export const comparesTranslateSolo = (compare: TokenCompares) => {
  return comparesTranslate[compare].replace(/(\s%.{1})/g, '')
}

function getSafeDate(date: unknown) {
  if (date && (typeof date === 'number' || typeof date === 'string' || date instanceof Date)) {
    return new Date(date)
  }

  return new Date()
}

function isBooleanToken(token: Token, _value: TokenValues): _value is boolean {
  return token.type === 'boolean'
}

function isDateToken(token: Token, _value: TokenValues): _value is Date {
  return token.type === 'date'
}

function isDateBetweenToken(token: Token, value: TokenValues, compares: TokenCompares): value is Date[] {
  return token.type === 'date' && compares === 'between' && Array.isArray(value)
}

function isArrayAutocompleteToken(
  token: Token,
  value: TokenValues
): value is (string | { text: string; value: string })[] {
  return !!token.autocomplete && Array.isArray(value)
}
function isObjectAutocompleteToken(token: Token, value: TokenValues): value is { text: string; value: string } {
  return !!token.autocomplete && !Array.isArray(value)
}

export const prettifyTokenValue = (token: Token, value: TokenValues, type: TokenCompares) => {
  if (isBooleanToken(token, value)) {
    return value ? 'Да' : 'Нет'
  }

  if (isDateBetweenToken(token, value, type)) {
    const dates = value.map((e) => format(getSafeDate(e), 'dd MMM yyyy'))

    if (dates.includes('Invalid date')) {
      return 'Ошибка!'
    }

    return `${dates[0]} и ${dates[1]}`
  }

  if (isDateToken(token, value)) {
    const date = format(getSafeDate(value), 'dd.MM.yyyy')
    return date === 'Invalid date' ? '' : date
  }

  if (isArrayAutocompleteToken(token, value)) {
    const values = value.map((e) => {
      if (typeof e === 'object') {
        return e.text
      }

      return e
    })

    if (values.length > 2) {
      const firstTwo = values.slice(0, 2)
      const rest = values.slice(3)

      if (rest.length) {
        return firstTwo.join(', ') + ` (+${rest.length})`
      }

      return firstTwo.join(', ')
    }

    return values.join(', ')
  }

  if (isObjectAutocompleteToken(token, value)) {
    return value.text
  }

  return value || ''
}

export const getDefaultTokenValue = (type: TokenPlainTypes, compare: TokenCompares) => {
  if (compare === 'between') {
    if (type === 'number') {
      return [0, 1]
    }

    if (type === 'date') {
      return [startOfDay(new Date()).toISOString(), endOfDay(new Date()).toISOString()]
    }

    return ['', '']
  }

  if (type === 'date') {
    return new Date().toISOString()
  }

  if (type === 'array') {
    return []
  }

  if (type === 'number') {
    return 0
  }

  if (type === 'boolean') {
    return false
  }

  return ''
}

export const Filters: Record<string, Token[]> = {
  calls: [
    {
      value: 'manager',
      type: 'array',
      name: 'Менеджер',
      autocomplete: '/custom?m=Call&f=manager&w=y',
      compares: ['contains', 'not contains'],
      disabled: false,
    },
    {
      value: 'startTime',
      type: 'date',
      name: 'Старт звонка',
      compares: ['between', 'greater than', 'not greater than'],
      disabled: false,
    },
    {
      value: 'endTime',
      type: 'date',
      name: 'Конец звонка',
      compares: ['between', 'greater than', 'not greater than'],
      disabled: false,
    },
    {
      value: 'answerTime',
      type: 'date',
      name: 'Ответ звонка',
      compares: ['between', 'greater than', 'not greater than'],
      disabled: false,
    },
    {
      value: 'incoming',
      type: 'boolean',
      name: 'Входящий',
      compares: ['is', 'not is'],
      disabled: false,
    },
    {
      value: 'answered',
      type: 'boolean',
      name: 'Отвечен',
      compares: ['is', 'not is'],
      disabled: false,
    },
  ],

  cashes: [
    {
      value: 'createdAt',
      type: 'date',
      name: 'Дата создания',
      compares: ['between', 'greater than', 'not greater than'],
      disabled: false,
    },
    {
      value: 'account',
      type: 'array',
      name: 'Счет',
      autocomplete: '/custom?m=CashAccount&f=name&v=_id&office=y',
      compares: ['contains', 'not contains'],
      disabled: false,
    },
    {
      value: 'cashier',
      type: 'array',
      name: 'Кассир',
      autocomplete: '/custom?m=Cash&f=cashier.credentials&v=cashier._id&w=y',
      compares: ['contains', 'not contains'],
      disabled: false,
    },
    {
      value: 'client',
      type: 'array',
      name: 'Клиент',
      autocomplete: '/custom?m=Cash&f=client.name&v=client._id&w=y',
      compares: ['contains', 'not contains'],
      disabled: false,
    },
    {
      value: 'amount',
      type: 'number',
      name: 'Сумма',
      compares: ['between', 'greater than', 'not greater than'],
      disabled: false,
    },
    {
      value: 'comment',
      type: 'string',
      name: 'Тип клиента',
      autocomplete: '/custom?m=Cash&f=comment&w=y',
      compares: ['is', 'contains', 'not is', 'not contains'],
      disabled: false,
    },
  ],

  clients: [
    {
      value: 'createdAt',
      type: 'date',
      name: 'Дата создания',
      compares: ['between', 'greater than', 'not greater than'],
      disabled: false,
    },
    {
      value: 'name',
      type: 'array',
      name: 'Имя клиента',
      autocomplete: '/custom?m=Client&f=name&w=y',
      compares: ['contains', 'not contains'],
      disabled: false,
    },
    {
      value: 'clientType',
      type: 'array',
      name: 'Тип клиента',
      autocomplete: '/custom?m=Client&f=clientType&w=y',
      compares: ['contains', 'not contains'],
      disabled: false,
    },
    {
      value: 'isProvider',
      type: 'boolean',
      name: 'Поставщик',
      compares: ['is', 'not is'],
      disabled: false,
    },
    {
      value: 'isConflict',
      type: 'boolean',
      name: 'Конфликтный',
      compares: ['is', 'not is'],
      disabled: false,
    },
    {
      value: 'allowedEmailNotifications',
      type: 'boolean',
      name: 'Разрешены почтовые уведомления',
      compares: ['is', 'not is'],
      disabled: false,
    },
    {
      value: 'allowedNotifications',
      type: 'boolean',
      name: 'Разрешены уведомления',
      compares: ['is', 'not is'],
      disabled: false,
    },
  ],

  users: [
    {
      value: 'role',
      type: 'array',
      name: 'Роль',
      autocomplete: '/custom?m=User&f=role&w=y',
      compares: ['contains', 'not contains'],
      disabled: false,
    },
    {
      value: 'credentials',
      type: 'array',
      name: 'Имя пользователя',
      autocomplete: '/custom?m=User&f=credentials&w=y',
      compares: ['contains', 'not contains'],
      disabled: false,
    },
    {
      value: 'username',
      type: 'array',
      name: 'Ник пользователя',
      autocomplete: '/custom?m=User&f=username&w=y',
      compares: ['contains', 'not contains'],
      disabled: false,
    },
  ],

  orders: [
    {
      value: 'id',
      type: 'number',
      name: '№ Заказа',
      compares: ['is', 'between', 'greater than', 'not is', 'not greater than'],
      disabled: false,
    },
    {
      value: 'createdAt',
      type: 'date',
      name: 'Дата создания',
      compares: ['between', 'greater than', 'not greater than'],
      disabled: false,
    },
    {
      value: 'estimatedCloseAt',
      type: 'date',
      name: 'Примерная дата готовности',
      compares: ['between', 'greater than', 'not greater than'],
      disabled: false,
    },
    {
      value: 'closedAt',
      type: 'date',
      name: 'Дата закрытия',
      compares: ['between', 'greater than', 'not greater than'],
      disabled: false,
    },
    {
      value: 'status',
      type: 'array',
      name: 'Статус',
      autocomplete: '/custom?m=Order&f=status&w=y',
      compares: ['contains', 'not contains'],
      disabled: false,
    },
    {
      value: 'master',
      type: 'array',
      name: 'Мастер',
      autocomplete: '/users',
      compares: ['contains', 'not contains'],
      disabled: false,
    },
    {
      value: 'manager',
      type: 'array',
      name: 'Менеджер',
      autocomplete: '/users',
      compares: ['contains', 'not contains'],
      disabled: false,
    },
    {
      value: 'phoneBrand',
      type: 'array',
      name: 'Бренд',
      autocomplete: '/custom?m=Order&f=phoneBrand&w=y',
      compares: ['contains', 'not contains'],
      disabled: false,
    },
    {
      value: 'phoneModel',
      type: 'array',
      name: 'Модель',
      autocomplete: '/custom?m=Order&f=phoneModel&w=y',
      compares: ['contains', 'not contains'],
      disabled: false,
    },
    {
      value: 'orderType',
      type: 'array',
      name: 'Тип заказа',
      autocomplete: '/custom?m=Order&f=orderType&w=y',
      compares: ['contains', 'not contains'],
      disabled: false,
    },
    {
      value: 'payed',
      type: 'boolean',
      name: 'Оплачено',
      compares: ['is', 'not is'],
      disabled: false,
    },
    {
      value: 'declaredPrice',
      type: 'number',
      name: 'Стоимость',
      compares: ['between', 'greater than', 'not greater than'],
      disabled: false,
    },
    {
      value: 'declaredDefect',
      type: 'string',
      name: 'Дефект',
      autocomplete: '/custom?m=Order&f=declaredDefect&w=y',
      compares: ['is', 'contains', 'not is', 'not contains'],
      disabled: false,
    },
    {
      value: 'kit',
      type: 'string',
      name: 'Комплектация',
      autocomplete: '/custom?m=Order&f=kit&w=y',
      compares: ['is', 'contains', 'not is', 'not contains'],
      disabled: false,
    },
    {
      value: 'customerName',
      type: 'string',
      name: 'Клиент',
      autocomplete: '/custom?m=Order&f=customer.name&w=y',
      compares: ['is', 'contains', 'not is', 'not contains'],
      disabled: false,
    },
    {
      value: 'serialNumber',
      type: 'string',
      name: 'Серийный номер',
      autocomplete: '/custom?m=Order&f=serialNumber&w=y',
      compares: ['is', 'contains', 'not is', 'not contains'],
      disabled: false,
    },
    {
      value: 'appearance',
      type: 'string',
      name: 'Внешний вид',
      autocomplete: '/custom?m=Order&f=appearance&w=y',
      compares: ['is', 'contains', 'not is', 'not contains'],
      disabled: false,
    },
  ],

  access: [],
}
