import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { ClickOutside, Intersect, Mutate, Resize, Ripple, Scroll, Touch } from 'vuetify/lib/directives'
import ru from 'vuetify/src/locale/ru'
import colors from '../../colors.json'

Vue.use(Vuetify, {
  directives: {
    Ripple,
    ClickOutside,
    Intersect,
    Mutate,
    Resize,
    Scroll,
    Touch,
  },
})

const vuetifyOpts = {
  rtl: false,
  lang: {
    locales: { ru },
    current: 'ru',
  },
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: colors.brand.DEFAULT,
        success: colors.success.DEFAULT,
        info: colors.info.DEFAULT,
        warning: colors.warning.DEFAULT,
        error: colors.danger.DEFAULT,
        softgrey: colors.gray.lightest,
      },
      light: {
        primary: colors.brand.DEFAULT,
        success: colors.success.DEFAULT,
        info: colors.info.DEFAULT,
        warning: colors.warning.DEFAULT,
        error: colors.danger.DEFAULT,
        softgrey: colors.gray.lightest,
        gray: colors.gray.DEFAULT,
        'gray-light': colors.gray.light,
        'gray-lighter': colors.gray.lighter,
        'gray-lightest': colors.gray.lightest,
      },
    },
  },
}

Vue.use(Vuetify)

export default new Vuetify(vuetifyOpts)
