import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { Office } from '@/typings/api/office'
@Module({
  namespaced: true,
  name: 'settings',
})
export default class Settings extends VuexModule {
  public office: any = null

  public menuMinified = false

  public audioplayerVolume = 0.5

  @Mutation
  SET_OFFICE(payload: string) {
    this.office = payload
  }

  @Mutation
  SET_MENU_MINIFIED(payload: boolean) {
    this.menuMinified = payload
  }

  @Mutation
  SET_AUDIOPLAYER_VOLUME(payload: number) {
    this.audioplayerVolume = payload
  }

  @Action
  async setOffice(payload: Office | null) {
    this.context.commit('SET_OFFICE', payload)
  }

  @Action
  async setMenuMinified(payload: boolean) {
    this.context.commit('SET_MENU_MINIFIED', payload)
  }

  @Action
  async setAudioplayerVolume(payload: number) {
    this.context.commit('SET_AUDIOPLAYER_VOLUME', payload)
  }
}
