
<aside v-if="isLoggedIn" ref="wrapper" class="w:h-full w:relative w:min-w-[288px] app-menu-wrapper">
  <app-menu-burger
    ref="burger"
    v-tippy="{ placement: 'right' }"
    content="Оставить боковое меню открытым"
    :class="{ [baseBurgerClasses]: true }"
    :hover="hover"
    @toggle.stop="handleMenuUnfold"
  />

  <section
    ref="menu"
    :class="{ 'w:elevation-12': hover, 'w:elevation-2': !hover, [baseMenuClasses]: true }"
    class="w:isolate"
  >
    <app-menu-header :minified="minified" @toggle="handleMenuFold" />
    <slot name="top" />
    <app-menu-links class="w:flex-grow" />
    <slot name="bottom" />
  </section>
</aside>
