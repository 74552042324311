
<v-dialog v-model="modal" persistent max-width="600">
  <div class="w:bg-white w:elevation-8 w:px-8 w:py-6">
    <base-heading variant="h2">Укажите вашу почту</base-heading>

    <div class="w:mt-4 w:space-y-6">
      <p class="w:text-gray-darker">
        Заходите в аккаунт удобнее с помощью почты, а так же получите возможность получения уведомлений и
        восстановления пароля.
      </p>

      <v-form ref="form" class="w:space-y-6" @submit.prevent="handleSubmit">
        <base-input v-model.trim="email" :rules="emailField" label="Почта" icon="mdi-email" />

        <div class="w:flex w:space-x-4">
          <v-btn class="w:ml-auto" text @click="modal = false">Отложить</v-btn>
          <v-btn type="submit" :loading="isLoading" depressed color="primary">
            <i-bx-bxs-save class="w:w-5 w:h-5 w:mr-2 w:text-white" />
            Изменить
          </v-btn>
        </div>
      </v-form>
    </div>
  </div>
</v-dialog>
