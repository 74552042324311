
<v-app>
  <notification-app-update />
  <user-email-alert v-if="showMenu && !userHasEmail" />

  <main class="w:flex w:h-full w:flex-nowrap" :class="{ 'w:flex-col': isMobile }">
    <transition
      appear
      enter-active-class="w:transform w:ease-in-out w:duration-300"
      enter-class="-translate-x-64"
      enter-to-class="translate-x-0"
      leave-active-class="w:transform w:ease-in-out w:duration-300"
      leave-class="translate-x-0"
      leave-to-class="-translate-x-64"
    >
      <app-menu v-if="showMenu" />
    </transition>
    <fade-transition duration="w:duration-500" mode="out-in">
      <router-view></router-view>
    </fade-transition>
  </main>
</v-app>
