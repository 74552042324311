
<responsive-menu
  v-if="user"
  remove-bg
  :menu-props="{
    'offset-y': true,
    'nudge-top': -8,
    'nudge-left': -16,
    'min-width': 256,
    'max-width': 256,
    attach: '.app-menu-user',
    'content-class': '!w:rounded-md w:backdrop-filter w:backdrop-blur-sm',
  }"
>
  <template #activator="{ on, attrs }">
    <button
      v-ripple
      class="
        app-menu-user
        w:relative w:w-full w:rounded-lg
        group
        hover:w:bg-black-light
        focus:w:outline-none focus:w:bg-black-light
        w:py-4 w:items-center w:pl-7 w:pr-16 w:flex
      "
      v-bind="attrs"
      v-on="on"
    >
      <user-avatar
        :slug="user.email || user.username"
        size="x-small"
        :rounded="false"
        class="w:bg-white w:elevation-0 w:mr-4 w:border-gray w:border-solid w:border-2"
      ></user-avatar>

      <div class="w:overflow-hidden">
        <p class="w:text-base w:text-gray-lightest w:truncate">{{ userCredentials }}</p>
      </div>

      <app-menu-toggle
        v-if="!minified && !isMobile"
        class="
          w:absolute w:right-8 w:opacity-0 w:text-gray-lightest
          group-hover:w:opacity-100
          w:transition w:duration-300
        "
        @toggle.stop="handleToggle"
      />
    </button>
  </template>

  <div class="w:bg-black-light/50 w:backdrop-filter w:backdrop-blur-[2px] w:elevation-2 w:space-y-4 w:py-2 w:px-2">
    <div class="w:flex w:items-center w:space-x-1 w:px-3">
      <user-avatar
        :slug="user.email || user.username"
        size="small"
        class="w:bg-white w:elevation-0 w:mr-4 w:border-gray w:border-solid w:border-2"
      ></user-avatar>

      <div class="w:overflow-hidden">
        <p class="w:text-base w:text-gray-lightest w:truncate">{{ userCredentials }}</p>
        <p class="w:text-xs w:text-left w:text-gray w:truncate">{{ userAdditional }}</p>
      </div>
    </div>
    <div class="w:w-full w:h-[1px] w:bg-gray-darkest"></div>
    <button
      class="
        w:flex
        w:items-center
        w:justify-center
        w:bg-black-light
        w:rounded-md
        w:w-full
        w:px-3
        w:py-2
        w:transition
        w:duration-300
        hover:w:bg-black-light/75
        focus:w:outline-none focus:w:ring
        !w:text-gray-dark
        w:tracking-wider w:text-base w:z-10 w:flex w:space-x-4 w:items-center
      "
      @click="handleUserLeave"
    >
      <i-bx-bx-log-out class="w:w-6 w:h-6 w:mr-2 w:text-danger w:block" />
      Выйти из системы
    </button>
  </div>
</responsive-menu>
