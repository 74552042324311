
<div class="ug-responsive-menu">
  <template v-if="!isMobile">
    <v-menu v-model="model" v-bind="menuProps">
      <template #activator="{ on, attrs }">
        <slot name="activator" :on="on" :attrs="attrs"></slot>
      </template>
      <div v-if="!custom" class="w:flex w:flex-col" :class="{ 'w:bg-white w:elevation-8': !removeBg }">
        <div class="w:flex-grow ug:scroll">
          <slot name="default" :close="close"></slot>
        </div>
      </div>
      <slot v-else name="default" :close="close"></slot>
    </v-menu>
  </template>
  <template v-else>
    <v-bottom-sheet v-model="model" v-bind="sheetProps">
      <template #activator="{ on, attrs }">
        <slot name="activator" :on="on" :attrs="attrs"></slot>
      </template>
      <div
        v-if="!custom"
        class="w:max-h-[calc(50vh)] w:flex w:flex-col"
        :class="{ 'w:bg-white w:elevation-8': !removeBg }"
      >
        <div class="w:flex-grow ug:scroll">
          <p class="w:px-2 w:pt-4 w:font-medium w:tracking-wide w:truncate w:text-gray-darkest">{{ label }}</p>
          <slot name="default" :close="close"></slot>
        </div>
      </div>
      <slot v-else name="default" :close="close"></slot>
    </v-bottom-sheet>
  </template>
</div>
