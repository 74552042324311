
<li>
  <router-link
    class="
      app-menu__link
      w:block w:rounded-md w:px-3 w:py-2 w:transition w:duration-300
      hover:w:bg-black-light/50
      focus:w:outline-none focus: focus: focus: focus: focus: focus: focus: focus: focus:w:ring
      !w:text-gray-dark
      w:tracking-wider w:text-base w:flex w:no-underline w:space-x-4 w:items-center
    "
    :class="{ 'w:bg-black-light/50 !w:text-gray-light hover:w:bg-black-light/25': dropdownMinified }"
    :exact-active-class="
      dropdownMinified ? 'w:bg-black-light/25 !w:text-gray-light' : 'w:bg-black-light/75 !w:text-brand-light'
    "
    :to="{ name: linkName }"
    @click.native="$emit('click', $event)"
  >
    <slot></slot>
  </router-link>
</li>
