import { apiUrl, apiVersion } from '@/config'

export const config = {
  baseURL: `${apiUrl}/${apiVersion}`,
  withCredentials: true,
  validateStatus: (status: any) => {
    return status < 500 // default
  },
  timeout: 15 * 1000,
}
