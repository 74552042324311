
<li class="w:relative">
  <app-menu-dropdown-button
    ref="button"
    class="app-menu__dropdown"
    :is-active="isActive"
    :is-highlighted="isHighlighted"
    :minified="minified"
    @click="handleClick"
  >
    <slot></slot>
  </app-menu-dropdown-button>

  <template v-if="!minified">
    <collapse-transition>
      <ul v-if="isActive" class="w:list-none w:relative !w:pl-12 w:space-y-2 w:pt-3">
        <div
          class="w:w-[2px] w:top-4 w:left-5.5 h-[calc(100%_-_16px)] w:bg-black-light w:rounded-full w:absolute"
        ></div>
        <slot name="list"></slot>
      </ul>
    </collapse-transition>
  </template>

  <responsive-menu
    v-if="minified"
    remove-bg
    :menu-props="{
      activator: button,
      'offset-y': true,
      'offset-x': true,
      'min-width': 0,
      'nudge-left': -32,
      'nudge-top': 40,
      'content-class': '!w:rounded-md w:backdrop-filter w:backdrop-blur-sm',
    }"
  >
    <div class="w:bg-black-light/50 w:elevation-2 w:py-2 w:px-2">
      <ul class="w:list-none !w:text-white w:relative !w:pl-0 w:space-y-2">
        <slot name="list"></slot>
      </ul>
    </div>
  </responsive-menu>
</li>
