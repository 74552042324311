
<nav class="w:relative w:h-auto w:px-6 w:pb-8 w:py-1 ug:scroll-dark">
  <ul class="w:list-none w:space-y-3 !w:pl-0">
    <template v-for="link in links">
      <app-menu-link
        v-if="link.single && $can('access', 'Default', link.name)"
        :key="link.key"
        :link-name="link.name"
        @click="$emit('click', $event)"
      >
        <template v-if="link.key === 'dashboard'">
          <i-bx-bxs-dashboard class="w:w-7 w:h-7 w:block" />
        </template>
        <span v-if="!minified" class="app-menu__link-title">{{ link.title }}</span>
      </app-menu-link>
      <app-menu-dropdown
        v-if="link.multiple && getAllowedLinksCount(link.list) > 0"
        :key="link.key"
        :minified="minified"
        :is-active="link.active"
        :is-highlighted="link.highlighted"
        @change="handleDropdownToggle(link.key, $event)"
      >
        <template v-if="link.key === 'data'">
          <i-fluent-document-bullet-list-24-filled class="w:w-7 w:h-7 w:block" />
        </template>
        <template v-else-if="link.key === 'reports'">
          <i-bx-bxs-report class="w:w-7 w:h-7 w:block" />
        </template>
        <template v-else-if="link.key === 'settings'">
          <i-ri-settings-5-fill class="w:w-7 w:h-7 w:block" />
        </template>
        <span v-if="!minified" class="app-menu__link-title">{{ link.title }}</span>

        <template #list>
          <template v-for="listItem in link.list">
            <app-menu-link
              v-if="$can('access', 'Default', listItem.name)"
              :key="listItem.name"
              :dropdown-minified="minified"
              :link-name="listItem.name"
              @click="$emit('click', $event)"
            >
              <span class="app-menu__link-title">{{ listItem.title }}</span>
            </app-menu-link>
          </template>
        </template>
      </app-menu-dropdown>
    </template>
  </ul>
</nav>
