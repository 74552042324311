import { AuthInput, User } from '@/typings/api/auth'
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { buildGuestAbilities, tryUpdateRoleAbilities } from '@/plugins/roles'
import * as authAPI from '@/api/auth'
import * as userAPI from '@/api/user'
import { settingsModule, filtersModule } from '@/store'
import { cloneDeep } from 'lodash'

@Module({
  namespaced: true,
  name: 'auth',
})
export default class Auth extends VuexModule {
  public user: User | null = null

  public isLoggedIn = false

  get getUser() {
    return this.user
  }

  @Mutation
  LOGIN(payload: User) {
    this.user = payload
    this.isLoggedIn = true
  }

  @Mutation
  LOGOUT() {
    this.user = null
    this.isLoggedIn = false
  }

  @Action
  async login(payload: AuthInput) {
    const response = await authAPI.login(payload)

    if (response.status !== 200) {
      return false
    }

    const user = response.data

    this.context.commit('LOGIN', user)

    await filtersModule.initDefaultFilters()
    await tryUpdateRoleAbilities(user.role)

    return true
  }

  @Action
  async logout() {
    try {
      const response = await authAPI.logout({ id: this.user?._id })

      if (response.status !== 200) {
        return false
      }

      this.context.commit('LOGOUT')
      await settingsModule.setOffice(null)
      await filtersModule.clearFilters()

      document.location.reload()

      return true
    } catch (error) {
      return false
    }
  }

  @Action
  async socket_userUpdated(evt: string) {
    if (evt === this.user?._id) {
      const response = await userAPI.getOne(evt)

      if (response.status === 200) {
        this.context.commit('LOGIN', response.data)

        if (!response.data.role && !response.data.isAdmin && !response.data.isDirector) {
          await buildGuestAbilities('update')

          return
        }

        await tryUpdateRoleAbilities(response.data.role)
      }
    }
  }

  @Action
  async socket_workspaceSubscriptionUpdated(evt: string) {
    const user: any = this.user

    if (evt === user?.workspace._id) {
      const response = await userAPI.getOne(user._id)

      if (response.status === 200) {
        this.context.commit('LOGIN', response.data)
      }
    }
  }

  @Action
  async socket_userDeleted(evt: string) {
    if (evt === this.user?._id) {
      this.context.dispatch('logout')
    }
  }
}
