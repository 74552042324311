
<div class="w:text-sm w:px-7 w:z-2 w:relative w:pb-4 w:items-center w:flex w:justify-between">
  <span class="w:text-gray-darker">Тех. Поддержка</span>
  <a
    class="!w:text-white !w:no-underline w:border-b w:border-solid w:border-info w:border-0 w:leading-7 w:font-medium"
    href="https://t.me/joinchat/lj7RhYaxIkQ2ZTUy"
    target="_blank"
  >
    @remuggsm
  </a>
</div>
