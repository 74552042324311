
<button
  ref="toggle"
  class="
    app-menu-toggle
    w:rounded-md w:p-0.5 w:transition w:duration-300
    focus:w:ring focus:w:outline-none
    w:right-6 w:transform
    group
  "
  @click="$emit('toggle', $event)"
>
  <i-radix-icons-double-arrow-left class="w:w-6 w:h-6 w:text-current w:block" />
</button>
