import Vue from 'vue'
import VueNotification from '@kugatsu/vuenotification'
import gsap from 'gsap'

Vue.use(VueNotification, {
  timer: 5,

  showCloseIcn: true,

  position: 'topLeft',

  animateIn(): any {
    return gsap.from(
      // @ts-ignore
      this.notificationEl,
      {
        y: -128,
        duration: 0.3,
      }
    )
  },

  animateOut(): any {
    return gsap.to(
      // @ts-ignore
      this.notificationEl,
      {
        x: -128,
        opacity: 0,
        duration: 0.3,
      }
    )
  },
})
