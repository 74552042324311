import { AxiosSuccess, AxiosError } from '@/helpers/axiosHelper'
import { AuthOutput, AuthInput, LogoutInput, User } from '@/typings/api/auth'
import { bootstrap, AxiosResponseAPI } from '@/helpers/axiosHelper'

export const { http, emits } = bootstrap('auth', {
  updatedAll: 'auths updated',
  updated: 'auth updated',
  deleted: 'auth deleted',
  created: 'auth created',
})

export async function check(data: { id: string }): AxiosResponseAPI<any> {
  try {
    const response = await http.request<any>({
      url: `/check`,
      method: 'post',
      data,
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function login(data: AuthInput): AxiosResponseAPI<AuthOutput> {
  try {
    const response = await http.post<AuthOutput>(
      `/login`,
      {
        ...data,
      },
      {
        maxRedirects: 0,
        withCredentials: true,
      }
    )

    return response as AxiosSuccess<AuthOutput>
  } catch (error) {
    return error as AxiosError
  }
}

export async function logout(data: LogoutInput): AxiosResponseAPI<User> {
  try {
    const response = await http.post<User>(
      `/logout`,
      {
        ...data,
      },
      {
        maxRedirects: 0,
        withCredentials: true,
      }
    )

    return response as AxiosSuccess<User>
  } catch (error) {
    return error as AxiosError
  }
}
