import { authModule } from '@/store'
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import * as orderStatusAPI from '@/api/orderStatus'

type GroupedStatus = {
  title: string
  color: string
  statuses: {
    title: string
  }[]
}

type Status = {
  text: string
  value: string
  color: string
}

@Module({
  namespaced: true,
  name: 'orderStatus',
})
export default class Settings extends VuexModule {
  public groupedStatuses: GroupedStatus[] = []

  get statuses() {
    const statuses: Status[] = []

    this.groupedStatuses?.forEach((group) => {
      group?.statuses?.forEach((status) =>
        statuses.push({
          text: status.title,
          value: status.title,
          color: group.color,
        })
      )
    })

    return statuses
  }

  @Mutation
  SET_STATUSES(payload: GroupedStatus[]) {
    this.groupedStatuses = payload
  }

  @Action
  async getStatuses() {
    const response = await orderStatusAPI.getGroupedStatuses()

    if (response.status !== 200) {
      setTimeout(() => {
        if (authModule.isLoggedIn) {
          this.context.dispatch('getStatuses')
        }
      }, 5000)
      return
    }

    this.context.commit('SET_STATUSES', response.data)
  }

  @Action
  async socket_orderStatusesUpdated() {
    this.context.dispatch('getStatuses')
  }
}
