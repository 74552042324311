
<v-btn
  class="ug-base-btn"
  :type="type"
  :to="to"
  :text="text"
  :small="small"
  :outlined="outlined"
  :loading="loading"
  :icon="!!icon"
  :disabled="disabled"
  :depressed="depressed"
  :color="color"
  :block="block"
  @click="handleClick"
  @blur.native="handleBlur"
>
  <template v-if="renderSlot">
    <slot></slot>
  </template>
  <template v-else>
    <template v-if="icon && label">
      <v-icon :size="iconSize" left>{{ icon }}</v-icon>
      <span>{{ label }}</span>
    </template>
    <template v-else-if="icon && !label">
      <v-icon :size="iconSize">{{ icon }}</v-icon>
    </template>
    <template v-else>
      <v-icon v-if="iconLeft" :size="iconSize" left>{{ iconLeft }}</v-icon>
      <span>{{ label }}</span>
      <v-icon v-if="iconRight" :size="iconSize" right>{{ iconRight }}</v-icon>
    </template>
  </template>
</v-btn>
