import Vue from 'vue'
import VueTippy, { TippyComponent } from 'vue-tippy'

import '@/assets/styles/tippy.scss'

Vue.use(VueTippy, {
  directive: 'tippy', // => v-tippy
  flipDuration: 0,
  interactive: true,
  animation: 'shift-toward',
  theme: 'ug',
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: true,
      },
    },
  },
})

Vue.component('tippy', TippyComponent)
