import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { authModule } from '@/store'
import { config } from '@/config/axiosConfig'
import { isDevelopement } from '@/config'

export type AxiosSuccess<T> = { status: 200 } & AxiosResponse<T>
export type AxiosError = { status: 400 | 404 | 500 | 401; message: string }

export type AxiosResponseAPI<T> = Promise<AxiosSuccess<T> | AxiosError>

export const interceptors = {
  baseReq(config: AxiosRequestConfig) {
    config = {
      ...config,
    }

    return config
  },
  baseReqError(error: any): Promise<never> {
    isDevelopement && console.debug('Request error', error.response.data)

    return Promise.reject(error.response.data)
  },
  async baseRes(response: AxiosResponse<any>) {
    isDevelopement && console.debug('Request response', response)

    if (response.status === 401 && authModule.isLoggedIn) {
      await authModule.logout()
    }

    return response
  },
  baseResError(error: any) {
    if (!error.response) {
      return Promise.reject({ status: 500, message: 'Не удалось отправить запрос на сервер' })
    }
    isDevelopement && console.debug('Result error', error.response.data)

    return Promise.reject(error.response.data)
  },
}

export function bootstrap<T extends Record<string, string>>(endpoint: string, emits: T) {
  const axiosConfig = {
    ...config,
    baseURL: `${config.baseURL}/${endpoint}`,
  }

  const http = axios.create(axiosConfig)

  http.interceptors.request.use(interceptors.baseReq, interceptors.baseReqError)
  http.interceptors.response.use(interceptors.baseRes, interceptors.baseResError)

  return {
    config: axiosConfig,
    http,
    emits,
  }
}
