
<app-menu-mobile v-if="isMobile">
  <template #top>
    <app-menu-office />
  </template>
  <template #bottom>
    <app-menu-help />
    <app-menu-staging />
  </template>
</app-menu-mobile>
<app-menu-desktop v-else>
  <template #top>
    <app-menu-office />
  </template>
  <template #bottom>
    <app-menu-help />
    <app-menu-staging />
  </template>
</app-menu-desktop>
