
<transition
  :enter-active-class="`w:ease-in-out ${duration}`"
  enter-class="w:opacity-0"
  enter-to-class="w:opacity-100"
  :leave-active-class="`w:ease-in-out ${duration}`"
  leave-class="w:opacity-100"
  leave-to-class="w:opacity-0"
  v-bind="$attrs"
>
  <slot></slot>
</transition>
