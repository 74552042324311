import { bootstrap, AxiosResponseAPI, AxiosSuccess, AxiosError } from '@/helpers/axiosHelper'

export const { http, emits } = bootstrap('role-ability', {
  updatedAll: 'roleAbilities updated',
})

export const roleAbilityTypes = ['boolean', 'string', 'array'] as const
export const roleAbilityOperators = ['equals', 'in array', 'not equals', 'not in array'] as const

export type RoleAbilityType = typeof roleAbilityTypes[number]
export type RoleAbilityOperator = typeof roleAbilityOperators[number]

export type RoleAbility = {
  value: string | boolean | Array<string>
  name: string
  description: string
  operator: RoleAbilityOperator
  type: RoleAbilityType
  autocomplete: string
}

export async function getAll(): AxiosResponseAPI<any> {
  try {
    const response = await http.request<RoleAbility[]>({
      url: ``,
      method: 'get',
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function create(payload: RoleAbility): AxiosResponseAPI<any> {
  try {
    const response = await http.request<RoleAbility>({
      url: ``,
      method: 'post',
      data: payload,
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function updateById(id: string, payload: Partial<RoleAbility>): AxiosResponseAPI<any> {
  try {
    const response = await http.request<RoleAbility>({
      url: `/${id}`,
      method: 'put',
      data: payload,
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function deleteById(id: string): AxiosResponseAPI<any> {
  try {
    const response = await http.request<RoleAbility>({
      url: `/${id}`,
      method: 'delete',
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}
