import { getRoutesAutocomplete } from '../router/routes'
import { Subject, UggsmAbility } from '@/typings/UggsmAbility'
import * as officeAPI from '@/api/office'
import * as roleAbilityAPI from '@/api/roleAbility'
import * as roleAPI from '@/api/role'
import { authModule } from '@/store'
import Vue from 'vue'
import { abilitiesPlugin } from '@casl/vue'
import { AbilityBuilder } from '@casl/ability'
import { each, find, map, filter } from 'lodash'
import { RoleAbility } from '@/typings/api/role'

export const fieldMatcher =
  <T extends string>(
    fieldsWrapper: {
      fields: T[]
      operator: 'in array' | 'not in array'
    }[]
  ) =>
  (field: T) => {
    const fields = fieldsWrapper[0]

    if (fields.operator === 'in array') {
      return fields.fields.includes(field)
    } else if (fields.operator === 'not in array') {
      return !fields.fields.includes(field)
    }

    return true
  }

export const getSubject = (ability: RoleAbility) => {
  return ability.resource ? ability.resource : Subject.DEFAULT
}

function buildStringAbilities({ abilities, can, cannot }: { abilities: RoleAbility[]; can: any; cannot: any }) {
  if (abilities.length) {
    each(abilities, (e) => {
      if (e) {
        if (e.operator === 'equals') {
          can(e.name, getSubject(e), e.value as string)
        } else if (e.operator === 'not equals') {
          cannot(e.name, getSubject(e), e.value as string)
        }
      }
    })
  }
}

function buildArrayAbilities({ abilities, can, cannot }: { abilities: RoleAbility[]; can: any; cannot: any }) {
  if (abilities.length) {
    each(abilities, (e) => {
      if (e) {
        // idk how to type this
        // @ts-ignore
        can(e.name, getSubject(e), [{ fields: e.value, operator: e.operator }])
      }
    })
  }
}

function buildBooleanAbilities({ abilities, can, cannot }: { abilities: RoleAbility[]; can: any; cannot: any }) {
  if (abilities.length) {
    each(abilities, (e) => {
      if (e) {
        if (e.value) {
          can(e.name, getSubject(e))
        } else {
          cannot(e.name, getSubject(e))
        }
      }
    })
  }
}

async function fullfillAbilities(existingAbilities: RoleAbility[]) {
  const abilitiesResponse = await roleAbilityAPI.getAll()

  if (abilitiesResponse.status !== 200) {
    return
  }

  for (const ability of abilitiesResponse.data) {
    if (find(existingAbilities, { name: ability.name })) continue

    delete ability._id
    delete ability.__v

    if (ability.name === 'access') {
      ability.value = map(getRoutesAutocomplete(), (e) => e.value)
    }

    if (ability.name === 'seeOffices') {
      const officesResponse = await officeAPI.getAll()

      if (officesResponse.status !== 200) {
        return
      }

      ability.value = map(officesResponse.data, (e) => e.name)
    }

    existingAbilities.push(ability)
  }
}

export async function buildAbility(data: {
  isAdmin?: boolean
  isDirector?: boolean
  role: string | undefined
  type: 'default' | 'update'
}) {
  const { can, cannot, rules, build } = new AbilityBuilder(UggsmAbility)

  if (data.isAdmin) {
    can('manage', Subject.ALL)

    console.log(
      `%cSetting up role Admin with method ${data.type}`,
      'background: #6a040f; color: #fafafa; padding: 6px 8px'
    )

    if (data.type === 'default') {
      // @ts-ignore
      return build({ fieldMatcher })
    } else {
      Vue.prototype.$ability.update(rules)
      return true
    }
  }

  if (data.isDirector) {
    can('manage', Subject.ALL)

    console.log(
      `%cSetting up role Director with method ${data.type}`,
      'background: #ffba08; color: #fafafa; padding: 6px 8px'
    )

    if (data.type === 'default') {
      // @ts-ignore
      return build({ fieldMatcher })
    } else {
      Vue.prototype.$ability.update(rules)
      return true
    }
  }

  if (!data.role) {
    if (data.type === 'default') {
      // @ts-ignore
      return build({ fieldMatcher })
    } else {
      Vue.prototype.$ability.update(rules)
      return true
    }
  }

  const roleResponse = await roleAPI.getOneById(data.role)

  if (roleResponse.status !== 200) {
    return
  }

  if (!roleResponse.data?.title) {
    if (data.type === 'default') {
      // @ts-ignore
      return build({ fieldMatcher })
    } else {
      Vue.prototype.$ability.update(rules)
      return true
    }
  }

  console.log(
    `%cSetting up role ${roleResponse.data.title} with method ${data.type}`,
    'background: #023e8a; color: #fafafa; padding: 6px 8px'
  )

  const abilities = roleResponse.data.abilities || []

  await fullfillAbilities(abilities)

  buildStringAbilities({
    abilities: filter(abilities, { type: 'string' }),
    can,
    cannot,
  })
  buildArrayAbilities({
    abilities: filter(abilities, { type: 'array' }),
    can,
    cannot,
  })
  buildBooleanAbilities({
    abilities: filter(abilities, { type: 'boolean' }),
    can,
    cannot,
  })

  if (data.type === 'default') {
    // @ts-ignore
    const ability = build({ fieldMatcher })

    return ability
  } else {
    Vue.prototype.$ability.update(rules)
    return true
  }
}

export async function tryUpdateRoleAbilities(role: string | undefined) {
  if (authModule.user) {
    if (authModule.user.isAdmin || authModule.user.isDirector) {
      await buildAbility({
        isAdmin: authModule.user.isAdmin,
        isDirector: authModule.user.isDirector,
        role,
        type: 'update',
      })

      return
    }
  }

  if (role && authModule.user?.role === role) {
    await buildAbility({ role, type: 'update' })
  }
}

export async function buildGuestAbilities(type: 'update' | 'default' = 'default') {
  const { can, cannot, rules, build } = new AbilityBuilder(UggsmAbility)

  const abilities: any = []

  await fullfillAbilities(abilities)

  buildStringAbilities({
    abilities: filter(abilities, { type: 'string' }),
    can,
    cannot,
  })
  buildArrayAbilities({
    abilities: filter(abilities, { type: 'array' }),
    can,
    cannot,
  })
  buildBooleanAbilities({
    abilities: filter(abilities, { type: 'boolean' }),
    can,
    cannot,
  })

  if (type === 'default') {
    // @ts-ignore
    const ability = build({ fieldMatcher })

    return ability
  } else {
    Vue.prototype.$ability.update(rules)
    return true
  }
}

export async function initRoles() {
  const ability = await buildAbility({
    isAdmin: authModule.user?.isAdmin,
    isDirector: authModule.user?.isDirector,
    role: authModule.user?.role,
    type: 'default',
  })

  Vue.use(abilitiesPlugin, ability, { useGlobalProperties: true })
}
