
<button
  class="
    w:w-full w:block w:rounded-md w:px-3 w:py-2 w:transition w:duration-300
    hover:w:bg-black-light/50
    focus:w:outline-none focus: focus: focus: focus: focus: focus: focus:w:ring
    !w:text-gray-dark
    w:tracking-wider w:text-base w:flex w:items-center w:justify-between
  "
  :class="{ 'w:bg-black-light/75 !w:text-brand-light': isHighlighted }"
  @click="$emit('click', !isActive)"
>
  <div class="w:flex w:space-x-4 w:items-center">
    <slot></slot>
  </div>
  <div class="app-menu__dropdown-arrow">
    <i-bx-bx-chevron-down
      v-if="!minified"
      class="w:w-6 w:h-6 w:text-gray-dark w:transform w:transition w:duration-300 w:block"
      :class="{ 'w:rotate-180 !w:text-gray-lighter': isActive }"
    />
  </div>
</button>
