
<div id="ug-office-switcher" class="ug-office-switcher">
  <template v-if="isLoading">
    <skeleton width="160px" height="32px" rounded />
  </template>
  <template v-else>
    <u-popup
      label="Смена филиала"
      :menu-props="{
        minWidth: 1,
        offsetX: true,
        maxHeight: 300,
        contentClass: 'ug:scroll !w:z-9',
      }"
    >
      <template #activator="{ on }">
        <v-btn v-if="!currentOffice" key="no-office" small depressed color="error" v-on="on">Выберите филиал</v-btn>
        <v-btn v-else key="office" small depressed class="!w:bg-black-light/50" v-on="on">
          <div class="w:flex w:items-center">
            <span class="w:mr-2 w:text-lg w:mt-0.5 w:text-gray-light w:font-medium w:font-changa">
              {{ currentOffice.code }}
            </span>
            <span class="w:text-gray">{{ currentOffice.name }}</span>
          </div>
        </v-btn>
      </template>
      <template v-if="allowedOffices.length">
        <v-list dense>
          <v-list-item
            v-for="allowedOffice in allowedOffices"
            :key="allowedOffice.code"
            :disabled="isCurrentOffice(allowedOffice)"
            @click="selectOffice(allowedOffice)"
          >
            <v-list-item-icon>
              <span class="w:mr-2 w:text-lg w:font-bold w:font-changa">
                {{ allowedOffice.code }}
              </span>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="allowedOffice.name" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
      <template v-else>
        <div class="w:p-4 w:select-none">
          <span class="w:text-danger w:font-medium">Нет доступных филиалов</span>
        </div>
      </template>
    </u-popup>
  </template>
</div>
