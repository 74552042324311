// @ts-nocheck

export default {
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },

    screenWidth() {
      return this.$vuetify.breakpoint.width
    },
  },
}
