
<v-text-field
  v-bind="attrs"
  ref="input"
  v-model="model"
  v-mask="phoneMask"
  :error-count="Infinity"
  :type="type"
  :suffix="suffix"
  :single-line="singleLine"
  :rules="rules"
  :prepend-inner-icon="icon"
  :prefix="prefix"
  :placeholder="placeholder"
  :label="label"
  :hide-details="hideDetails"
  :disabled="disabled"
  :clearable="displayClearIcon"
  :validate-on-blur="validateOnBlur"
  dense
  @focus="handleFocus"
  @click:clear="$emit('clear', $event)"
  @blur="handleBlur"
>
  <template #append>
    <slot name="append"></slot>
  </template>
  <template #message="{ key, message }">
    <v-expand-transition>
      <span :key="key">
        {{ message }}
      </span>
    </v-expand-transition>
  </template>
</v-text-field>
