
<transition
  :name="name"
  @before-appear="beforeAppear"
  @appear="appear"
  @after-appear="afterAppear"
  @appear-cancelled="appearCancelled"
  @before-enter="beforeEnter"
  @enter="enter"
  @after-enter="afterEnter"
  @enter-cancelled="enterCancelled"
  @before-leave="beforeLeave"
  @leave="leave"
  @after-leave="afterLeave"
  @leave-cancelled="leaveCancelled"
>
  <slot></slot>
</transition>
