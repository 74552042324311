import _format from 'date-fns/format'
import ru from 'date-fns/locale/ru'
import formatISO from 'date-fns/formatISO'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import parse from 'date-fns/parse'
import { times as _times } from 'lodash'

export function format(date: Date | number, f: string) {
  return _format(date, f, { locale: ru })
}

export function rangeHelper(firstDate: string, secondDate: string) {
  return [
    formatISO(startOfDay(parse(firstDate, 'dd.MM.yyyy', new Date()))),
    formatISO(endOfDay(parse(secondDate, 'dd.MM.yyyy', new Date()))),
  ]
}

export function generateDateArray(t: number, f: string) {
  const result: any[] = []

  _times(t, (e) => {
    result.push(format(new Date(), f))
  })

  return result
}

export function startOfDayToISO(date: string, f: string) {
  return formatISO(startOfDay(parse(date, f, new Date())))
}

export function endOfDayToISO(date: string, f: string) {
  return formatISO(endOfDay(parse(date, f, new Date())))
}

export function parseToISO(date: string, f: string) {
  return formatISO(parse(date, f, new Date()))
}
