
<button
  class="
    app-menu-toggle
    w:rounded-md w:p-0.5 w:transition w:duration-300
    focus:w:ring focus:w:outline-none
    w:right-6 w:transform w:relative
    group
  "
  @mouseover="$emit('mouseover', $event)"
  @mouseleave="$emit('mouseleave', $event)"
  @click="$emit('toggle', $event)"
>
  <transition
    enter-active-class="absolute ease-in-out duration-100"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="absolute ease-in-out duration-100"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
    mode="out-in"
  >
    <i-bx-bx-menu v-if="!hover" key="1" class="app-menu-burger w:w-6 w:h-6 w:text-current w:block" />
    <i-radix-icons-double-arrow-left
      v-else
      key="2"
      class="app-menu-arrow w:none w:w-6 w:h-6 w:text-current w:block"
    />
  </transition>
</button>
