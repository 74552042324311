import 'reflect-metadata'
import { calculateViewport } from '@/helpers/calculateViewport'

import Vue from 'vue'
import store from '@/store'
import App from '@/App.vue'

import router from '@/router'
import { tryUpdateRoleAbilities, initRoles } from '@/plugins/roles'
import { initSentry } from '@/plugins/sentry'
initSentry()

import '@/plugins/axios'
import '@/plugins/notifications'
import '@/plugins/socket.io.ts'
import '@/plugins/printer'
import '@/plugins/v-mask'
import '@/plugins/markdown-directive'
import '@/plugins/tippy'
import '@/plugins/v-calendar'

import vuetify from '@/plugins/vuetify'
import 'virtual:windi.css'

import * as authAPI from '@/api/auth'
import * as roleAPI from '@/api/role'

import { authModule } from '@/store'
import { filtersModule } from '@/store'

Vue.config.productionTip = false

async function checkUserToken() {
  const userId = authModule.user?._id

  if (userId) {
    const response = await authAPI.check({ id: userId })

    if (response.status !== 200) {
      await authModule.logout()
    }
  }
}

const initApp = () => {
  calculateViewport()
  Promise.all([initRoles(), checkUserToken(), filtersModule.initDefaultFilters()]).then(() => {
    new Vue({
      sockets: {
        [roleAPI.emits.updatedAll](role: string) {
          tryUpdateRoleAbilities(role)
        },
      },

      store,
      vuetify,
      router,
      render: (h) => h(App),
    }).$mount('#app')
  })
}

initApp()
