import { User } from '@/typings/api/auth'
import { bootstrap, AxiosResponseAPI, AxiosSuccess, AxiosError } from '@/helpers/axiosHelper'

export type Users = User[]

export const { http, emits } = bootstrap('user', {
  updatedAll: 'users updated',
  updated: 'user updated',
  deleted: 'user deleted',
  created: 'user created',
})

export async function get(): AxiosResponseAPI<Users> {
  try {
    const response = await http.request<Users>({
      url: ``,
      method: 'get',
    })

    return response as AxiosSuccess<Users>
  } catch (error) {
    return error as AxiosError
  }
}

export async function getPaginated(data: any): AxiosResponseAPI<any> {
  try {
    const response = await http.request<any>({
      url: `/paginated`,
      method: 'post',
      data,
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function getAllByRole(roleId: string): AxiosResponseAPI<User> {
  try {
    const response = await http.request<User>({
      url: `/role/${roleId}`,
      method: 'get',
    })

    return response as AxiosSuccess<User>
  } catch (error) {
    return error as AxiosError
  }
}

export async function getAllByPost(post: string): AxiosResponseAPI<Pick<User, '_id' | 'credentials'>> {
  try {
    const response = await http.request<Pick<User, '_id' | 'credentials'>>({
      url: `/post/${post}`,
      method: 'get',
    })

    return response as AxiosSuccess<Pick<User, '_id' | 'credentials'>>
  } catch (error) {
    return error as AxiosError
  }
}

export async function assignUserPosts(post: string, userIds: string[]): AxiosResponseAPI<any> {
  try {
    const response = await http.request<any>({
      url: `/post/${post}`,
      method: 'put',
      data: { assign: userIds },
    })

    return response as AxiosSuccess<any>
  } catch (error) {
    return error as AxiosError
  }
}

export async function getAllUnassigned(): AxiosResponseAPI<User> {
  try {
    const response = await http.request<User>({
      url: `/role-unassigned`,
      method: 'get',
    })

    return response as AxiosSuccess<User>
  } catch (error) {
    return error as AxiosError
  }
}

export async function getOne(id: string): AxiosResponseAPI<User> {
  try {
    const response = await http.request<User>({
      url: `${id}`,
      method: 'get',
    })

    return response as AxiosSuccess<User>
  } catch (error) {
    return error as AxiosError
  }
}

export async function create(data: User): AxiosResponseAPI<User> {
  try {
    const response = await http.request<User>({
      url: ``,
      method: 'post',
      data,
    })

    return response as AxiosSuccess<User>
  } catch (error) {
    return error as AxiosError
  }
}

export async function createDirector(data: any): AxiosResponseAPI<User> {
  try {
    const response = await http.request<User>({
      url: `/director`,
      method: 'post',
      data,
    })

    return response as AxiosSuccess<User>
  } catch (error) {
    return error as AxiosError
  }
}

export async function update(id: string, data: Partial<User>): AxiosResponseAPI<User> {
  try {
    const response = await http.request<User>({
      url: `${id}`,
      method: 'put',
      data,
    })

    return response as AxiosSuccess<User>
  } catch (error) {
    return error as AxiosError
  }
}

export async function updatePassword(id: string, data: { password: true }): AxiosResponseAPI<User> {
  try {
    const response = await http.request<User>({
      url: `${id}/password`,
      method: 'put',
      data,
    })

    return response as AxiosSuccess<User>
  } catch (error) {
    return error as AxiosError
  }
}

export async function updateRoles(role: string, userIds: string[]): AxiosResponseAPI<User> {
  try {
    const response = await http.request<User>({
      url: `/role/${role}`,
      method: 'put',
      data: { userIds },
    })

    return response as AxiosSuccess<User>
  } catch (error) {
    return error as AxiosError
  }
}

export async function deleteUser(id: string, data: { newUserId: string }): AxiosResponseAPI<User> {
  try {
    const response = await http.request<User>({
      url: `/${id}`,
      method: 'delete',
      data,
    })

    return response as AxiosSuccess<User>
  } catch (error) {
    return error as AxiosError
  }
}
