import { config } from '@/config/axiosConfig'
import Vue from 'vue'
import axios from 'axios'
import { interceptors } from '@/helpers/axiosHelper'

const _axios = axios.create(config)

_axios.interceptors.request.use(interceptors.baseReq, interceptors.baseReqError)
_axios.interceptors.response.use(interceptors.baseRes, interceptors.baseResError)

Vue.prototype.$axios = _axios

export default _axios
