import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import { createRouterLayout } from 'vue-router-layout'
import { authModule } from '@/store'
// @ts-expect-error
import NProgress from 'nprogress'
import '@/assets/styles/plugins/nprogress.scss'

import { getRouteByName } from '@/router/routes'
import { UPDATE_APP_INTERVAL } from '@/config/constants'

Vue.use(Router)

const RouterLayout = createRouterLayout((layout) => {
  switch (layout) {
    case 'centered':
      return import('@/layouts/centered.vue')
    case 'default-padded':
      return import('@/layouts/default-padded.vue')
    case 'default-scrollable':
      return import('@/layouts/default-scrollable.vue')
    case 'default':
      return import('@/layouts/default.vue')
    default:
      return import('@/layouts/default.vue')
  }
})

const reportsRouter: RouteConfig[] = [
  {
    component: () => import('@/pages/reports/daily.vue'),
    ...getRouteByName('reports', 'reportsOrdersDaily'),
  },
  {
    component: () => import('@/pages/reports/orders-closed-incorrect.vue'),
    ...getRouteByName('reports', 'reportsOrdersWithoutWork'),
  },
  {
    component: () => import('@/pages/reports/orders-closed.vue'),
    ...getRouteByName('reports', 'reportsOrdersClosed'),
  },
  {
    component: () => import('@/pages/reports/orders-count.vue'),
    ...getRouteByName('reports', 'reportsOrdersCount'),
  },
  {
    component: () => import('@/pages/reports/orders-profit.vue'),
    ...getRouteByName('reports', 'reportsOrdersProfit'),
  },
]

const settingsRouter: RouteConfig[] = [
  {
    component: () => import('@/pages/settings/payments.vue'),
    ...getRouteByName('settings', 'settingsPayments'),
  },
  {
    component: () => import('@/pages/settings/roles.vue'),
    ...getRouteByName('settings', 'settingsRoles'),
  },
  {
    component: () => import('@/pages/settings/subscriptions.vue'),
    ...getRouteByName('settings', 'settingsSubscriptions'),
  },
  {
    component: () => import('@/pages/settings/offices.vue'),
    ...getRouteByName('settings', 'settingsOffices'),
  },
  {
    component: () => import('@/pages/settings/statuses.vue'),
    ...getRouteByName('settings', 'settingsStatuses'),
  },
  {
    component: () => import('@/pages/settings/posts.vue'),
    ...getRouteByName('settings', 'settingsPosts'),
  },
  {
    component: () => import('@/pages/settings/users.vue'),
    ...getRouteByName('settings', 'settingsUsers'),
  },
  {
    component: () => import('@/pages/settings/receipt/index.vue'),
    ...getRouteByName('settings', 'settingsReceipt'),
  },
  {
    component: () => import('@/pages/settings/receipt/edit-receipt.vue'),
    ...getRouteByName('settings', 'settingsReceiptEdit'),
  },
]

const consoleRouter: RouteConfig[] = [
  {
    component: () => import('@/pages/console.vue'),
    ...getRouteByName('console', 'console'),
  },
]

const defaultRouter: RouteConfig[] = [
  {
    component: () => import('@/pages/index.vue'),
    ...getRouteByName('default', 'index'),
  },
  {
    component: () => import('@/pages/register.vue'),
    ...getRouteByName('default', 'register'),
  },
  {
    component: () => import('@/pages/login.vue'),
    ...getRouteByName('default', 'login'),
  },
  {
    component: () => import('@/pages/access.vue'),
    ...getRouteByName('default', 'access'),
  },
  {
    component: () => import('@/pages/access-next.vue'),
    ...getRouteByName('default', 'accessNext'),
  },
  {
    component: () => import('@/pages/calls.vue'),
    ...getRouteByName('default', 'calls'),
  },
  {
    component: () => import('@/pages/calls-next.vue'),
    ...getRouteByName('default', 'callsNext'),
  },
  {
    component: () => import('@/pages/cash.vue'),
    ...getRouteByName('default', 'cash'),
  },
  {
    component: () => import('@/pages/cash-next.vue'),
    ...getRouteByName('default', 'cashNext'),
  },
  {
    component: () => import('@/pages/clients.vue'),
    ...getRouteByName('default', 'clients'),
  },
  {
    component: () => import('@/pages/clients-next.vue'),
    ...getRouteByName('default', 'clientsNext'),
  },
  {
    component: () => import('@/pages/orders.vue'),
    ...getRouteByName('default', 'orders'),
  },
  {
    component: () => import('@/pages/orders-next.vue'),
    ...getRouteByName('default', 'ordersNext'),
  },
]

const routes: RouteConfig[] = [...defaultRouter, ...consoleRouter, ...reportsRouter, ...settingsRouter]

export const router = new Router({
  routes: [
    {
      path: '/',
      component: RouterLayout,
      children: routes,
    },
  ],
  mode: 'history',
})

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = authModule.isLoggedIn
  const isGuestAllowed = to.meta?.isGuestAllowed
  const isManualNavigationToLogin = to.name === 'login' && to.query.logout !== '1'
  const isUserAdmin = authModule.user?.isAdmin

  if (!isLoggedIn && isGuestAllowed) {
    next()
  } else if (!isLoggedIn && !isGuestAllowed) {
    next({ name: 'login' })
  } else if (isUserAdmin && to.name !== 'console') {
    next({ name: 'console' })
  } else if (isManualNavigationToLogin) {
    next({ name: 'ordersNext' })
  } else {
    next()
  }
})

router.onReady(async () => {
  try {
    const { registerSW } = await import('virtual:pwa-register')

    registerSW({
      immediate: true,
      onRegistered(r) {
        r &&
          setInterval(() => {
            r.update()
          }, UPDATE_APP_INTERVAL)
      },
    })
  } catch (err) {
    //
  }
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  NProgress.set(0.7)
  next()
})

router.afterEach(() => {
  setTimeout(() => NProgress.done(), 500)
})

export default router
